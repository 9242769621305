import React from 'react';
import { Body } from '../Body/Body';
import { Heading } from '../Heading/Heading';
import './Footer.css';

export const Footer = () => (
  <footer className="gsd-footer">
    <Body extraSmall>
      GoldSpec Digital Ltd is a limited company registered in England and Wales.<br />
      Company Registration No. 10944413 | VAT No. 282305123<br />
      Registered Office: 15 Queen Square, Leeds, LS2 8AJ, England
    </Body>

    <div>
      <Heading tag="h4" small right>
        goldspecdigital.com
      </Heading>

      <Body extraSmall right>
        For questions concerning this invoice, please contact<br />
        Matthew Inamdar, 0770 207 6318, matt@goldspecdigital.com
      </Body>
    </div>
  </footer>
)
