import React from 'react';
import { Heading } from '../Heading/Heading';
import { Body } from '../Body/Body';
import { format, parseISO } from 'date-fns';
import './Details.css';

export const Details = ({ dateIssued, setDateIssued }) => {
  function onSetDateIssued() {
    const dateIssued = window.prompt(
      'Please enter the date issued',
      format(new Date(), 'y-MM-dd')
    );

    if (dateIssued === null) {
      return;
    }

    setDateIssued(parseISO(dateIssued));
  }

  return (
    <section className="gsd-details">
      <div className="gsd-details__company-info">
        <Heading tag="p" small spaced>
          GoldSpec Digital Ltd<br />
          15 Queen Square<br />
          Leeds, LS2 8AJ<br />
          0113 320 3299<br />
          hello@goldspecdigital.com
        </Heading>
      </div>

      <div className="gsd-details__invoice-info">
        <Heading
          className="gsd-details__date-issued"
          small
          spaced
          tag="p"
          onClick={onSetDateIssued}
        >
          {format(dateIssued, 'do LLL y')}
        </Heading>

        <Body small noMargin>
          Payment due 30 days upon date issued
        </Body>
      </div>
    </section>
  )
}
