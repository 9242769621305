import React, { useState } from 'react';
import { Header } from '../Header/Header';
import { Details } from '../Details/Details';
import { ClientDetails } from '../ClientDetails/ClientDetails';
import { Table } from '../Table/Table';
import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { Footer } from '../Footer/Footer';
import './Invoice.css';

export const Invoice = () => {
  const [invoiceNumber, setInvoiceNumber] = useState('Click to set');
  const [dateIssued, setDateIssued] = useState(new Date());
  const [clientDetails, setClientDetails] = useState(['Click to set']);
  const [items, setItems] = useState([]);

  return (
    <div className="gsd-invoice">
      <Header
        invoiceNumber={invoiceNumber}
        setInvoiceNumber={setInvoiceNumber}
      />

      <Details
        dateIssued={dateIssued}
        setDateIssued={setDateIssued}
      />

      <ClientDetails
        clientDetails={clientDetails}
        setClientDetails={setClientDetails}
      />

      <Table
        items={items}
        setItems={setItems}
        vatRate={20.00}
      />

      <PaymentDetails />

      <Footer />
    </div>
  )
}
