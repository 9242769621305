import React from 'react';
import classNames from 'classnames';
import './Heading.css';

export const Heading = ({
  children,
  className,
  onClick,
  tag = "h1",
  small = false,
  large = false,
  medium = false,
  caps = false,
  spaced = false,
  right = false
}) => {
  const classes = classNames(
    "gsd-heading",
    {
      "gsd-heading--caps": caps,
      "gsd-heading--l": large,
      "gsd-heading--m": medium,
      "gsd-heading--s": small,
      "gsd-heading--spaced": spaced,
      "gsd-heading--right": right
    },
    className
  );

  return React.createElement(
    tag,
    { className: classes, onClick },
    children
  )
}
