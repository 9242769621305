import React from 'react';
import { Heading } from '../Heading/Heading';
import './PaymentDetails.css';

export const PaymentDetails = () => (
  <section className="gsd-payment-details">
    <Heading small spaced>
      Please make payment to GoldSpec Digital Ltd<br />
      Sort Code: 40-27-15 | Account Number: 65256046<br />
      Thank You 🙂
    </Heading>
  </section>
)
