import React from 'react';
import { Heading } from '../Heading/Heading';
import './ClientDetails.css';

export const ClientDetails = ({ clientDetails, setClientDetails }) => {
  function onSetClientDetails() {
    let line = 1;
    const clientDetails = [];

    do {
      const clientDetail = window.prompt(`Please enter line ${line} of the client details`);

      if (clientDetail === null) {
        if (line === 1) {
          return;
        }

        break;
      }

      line += 1;
      clientDetails.push(clientDetail);
    } while (true);

    setClientDetails(clientDetails);
  }

  return (
    <section className="gsd-client-details">
      <Heading tag="h3" medium caps className="gsd-client-details__heading">
        Bill to
      </Heading>

      <Heading
        tag="p"
        small
        spaced
        className="gsd-client-details__details"
        onClick={onSetClientDetails}
      >
        {clientDetails.map((clientDetail, index) => {
          if (index < clientDetails.length - 1) {
            return (
              <React.Fragment key={index}>
                {clientDetail}<br />
              </React.Fragment>
            )
          }

          return (
            <React.Fragment key={index}>
              {clientDetail}
            </React.Fragment>
          )
        })}
      </Heading>
    </section>
  )
}
