import React from 'react';
import classNames from 'classnames';
import './Body.css';

export const Body = ({
  children,
  small = false,
  extraSmall = false,
  right = false,
  noMargin = false
}) => {
  const classes = classNames(
    'gsd-body',
    {
      'gsd-body--small': small,
      'gsd-body--xsmall': extraSmall,
      'gsd-body--right': right,
      'gsd-body--no-margin': noMargin
    }
  );

  return (
    <p className={classes}>
      {children}
    </p>
  )
}
