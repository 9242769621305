import React from 'react';
import './Table.css';

export const Table = ({ items, setItems, vatRate }) => {
  const subTotal = items.reduce((total, item) => {
    return total += item.price;
  }, 0);
  const totalVat = (subTotal / 100) * vatRate;
  const total = subTotal + totalVat;

  function onAddItem(index) {
    const description = window.prompt(
      'Please enter the item description',
      typeof index === 'undefined' ? '' : items[index].description
    );

    if (description === null) {
      return;
    }

    const price = window.prompt(
      'Please enter the item amount (in pounds)',
      typeof index === 'undefined' ? '' : items[index].price
    );

    if (price === null) {
      return;
    }

    const itemsCopy = JSON.parse(JSON.stringify(items));

    if (typeof index === 'undefined') {
      itemsCopy.push({
        description,
        price: parseFloat(price)
      });
    } else {
      itemsCopy[index] = {
        description,
        price: parseFloat(price)
      };
    }

    setItems(itemsCopy);
  }

  return (
    <table className="gsd-table">
      <thead className="gsd-table__head">
        <tr className="gsd-table__row">
          <th className="gsd-table__header gsd-table__header--full">
            Description
          </th>

          <th className="gsd-table__header">
            Price
          </th>
        </tr>
      </thead>

      <tbody className="gsd-table__body js-items">
        {items.map((item, index) => (
          <tr
            key={index}
            className={`gsd-table__row gsd-table__row--item ${index % 2 !== 0 ? 'gsd-table__row--odd' : ''}`}
            onClick={() => onAddItem(index)}
          >
            <td className="gsd-table__cell">
              {item.description}
            </td>

            <td className="gsd-table__cell gsd-table__cell--right">
              £{item.price.toFixed(2)}
            </td>
          </tr>
        ))}

        <tr
          className="gsd-table__row gsd-table__row--add-item gsd-table__row--ui"
          onClick={() => onAddItem()}
        >
          <td className="gsd-table__cell" colSpan="2">Click to add</td>
        </tr>
      </tbody>

      <tfoot className="gsd-table__foot">
        <tr className="gsd-table__row">
          <th className="gsd-table__header gsd-table__header--right">
            Subtotal
          </th>

          <td className="gsd-table__cell gsd-table__cell--right">
            £{subTotal.toFixed(2)}
          </td>
        </tr>

        <tr className="gsd-table__row">
          <th className="gsd-table__header gsd-table__header--right">
            VAT rate
          </th>

          <td className="gsd-table__cell gsd-table__cell--right">
            {vatRate.toFixed(2)}%
          </td>
        </tr>

        <tr className="gsd-table__row">
          <th className="gsd-table__header gsd-table__header--right">
            Total VAT
          </th>

          <td className="gsd-table__cell gsd-table__cell--right">
            £{totalVat.toFixed(2)}
          </td>
        </tr>

        <tr className="gsd-table__row">
          <th className="gsd-table__header gsd-table__header--right">
            Total
          </th>

          <td className="gsd-table__cell gsd-table__cell--right">
            £{total.toFixed(2)}
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
