import React from 'react';
import { Heading } from '../Heading/Heading';
import './Header.css';
import Logo from '../../img/logo-black.png';

export const Header = ({ invoiceNumber, setInvoiceNumber }) => {
  function onSetInvoiceNumber() {
    const invoiceNumber = window.prompt('Please enter the invoice number');

    if (invoiceNumber === null) {
      return;
    }

    setInvoiceNumber(parseInt(invoiceNumber));
  }

  return (
    <header className="gsd-header">
      <img
        className="gsd-header__logo"
        src={Logo}
        alt="GoldSpec Digital logo"
      />

      <div>
        <Heading large>
          Invoice
        </Heading>

        <Heading
          className="gsd-header__invoice-number"
          small
          tag="h2"
          onClick={onSetInvoiceNumber}
        >
          &nbsp;Invoice no. #{invoiceNumber}
        </Heading>
      </div>
    </header>
  )
}
